<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px" style="padding: 0 0 24px 0">
        <div style="border-bottom: 1px solid #E8E8E8;padding: 20px" class="mb40px">
          订单流转
        </div>
        <el-steps align-center process-status="wait" :active="active">
          <el-step v-for="(item, idx) in stepList" :key="idx">
            <div slot="icon">
            </div>
            <div slot="title">
              {{item}}
            </div>
            <div slot="description">
             <span style="display: block">
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].nickName||'- -'}}
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].phone}}
             </span>
              <span style="display: block">
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].gmtCreate | str2ymdhm}}
             </span>
            </div>
          </el-step>
        </el-steps>
      </div>
      <div class="content-main">
        <div class="form-label-base">基本信息</div>
        <div class="details-form-item">
          <span>订单编号：</span>
          <span>{{this.form.orderId||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>业务类型：</span>
          <span>{{this.form.orderType||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>客户名称：</span>
          <span>{{this.form.partnerName||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>结算客户：</span>
          <span>{{this.form.partnerAccountName||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">运输信息</div>
        <div class="details-form-item">
          <span>运输日期：</span>
          <span>{{this.form.transportTime|str2ymd}}</span>
        </div>
        <div class="details-form-item">
          <span>驾驶员：</span>
          <span>{{this.form.driverName||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>提货车号：</span>
          <span>{{this.form.plateNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>到货车号：</span>
          <span>{{this.form.cargoArrivalNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>提货单号：</span>
          <span>{{this.form.cargoDeliveryNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>中转单号：</span>
          <span>{{this.form.cargoTransferNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>起运地：</span>
          <span>{{this.form.sendAddressDetail||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>发货人：</span>
          <span>{{this.form.senderName||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>发货人手机号码：</span>
          <span>{{this.form.senderContactPhone||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>目的地：</span>
          <span>{{this.form.receiveAddressDetail||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收货人：</span>
          <span>{{this.form.receiverName||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收货人手机号码：</span>
          <span>{{this.form.receiverContactPhone||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">货物信息</div>

        <div class="details-form-item">
          <span>品名：</span>
          <span>{{this.form.category||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>吨数：</span>
          <span>{{this.form.categoryWeight||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>体积(立方米)：</span>
          <span>{{this.form.volume||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>件数：</span>
          <span>{{this.form.categoryPiece||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>货位：</span>
          <span>{{this.form.cargoLocation||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>去向：</span>
          <span>{{this.form.cargoDirection||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>保管员：</span>
          <span>{{this.form.keeper||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>货运员：</span>
          <span>{{this.form.freightAgent||'- -'}}</span>
        </div>

        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>库位：</span>
          <span>{{this.form.warehouseLocation||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>资源号：</span>
          <span>{{this.form.resourceId||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>规格：</span>
          <span>{{this.form.specification||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>型号：</span>
          <span>{{this.form.steelModel||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>钢种：</span>
          <span>{{this.form.steelType||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>产地：</span>
          <span>{{this.form.steelProduction||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>开单员：</span>
          <span>{{this.form.partOrder||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>收货员：</span>
          <span>{{this.form.receivingClerk||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>理货员：</span>
          <span>{{this.form.tallyClerk||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>审核员：</span>
          <span>{{this.form.checker||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>装卸班组：</span>
          <span>{{this.form.loadUploadCrew||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">费用信息</div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>运费(元)：</span>
          <span>{{this.form.earningFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>拼车费(元)：</span>
          <span>{{this.form.carpoolingFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='内转'">
          <span>吊装费(元)：</span>
          <span>{{this.form.liftingFee||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>提成系数(%)：</span>
          <span>{{this.form.driverBonusFee||'- -'}}</span>
        </div>

        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收入单价(元)：</span>
          <span>{{this.form.earningUnitPrice||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收入金额(元)：</span>
          <span>{{this.form.earningFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>过磅费(元)：</span>
          <span>{{this.form.weightFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>卸车费(元)：</span>
          <span>{{this.form.unloadFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>成本单价(元)：</span>
          <span>{{this.form.costUnitPrice||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>成本运费(元)：</span>
          <span>{{this.form.costCarrierFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>过路过桥费(元)：</span>
          <span>{{this.form.tollsFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>加油费(元)：</span>
          <span>{{this.form.fuelFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>罚款(元)：</span>
          <span>{{this.form.fine||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>其他费用(元)：</span>
          <span>{{this.form.otherFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>信息费(元)：</span>
          <span>{{this.form.messageFee||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">其他信息</div>

        <div style="margin-bottom: 20px">
          <span>下单备注：</span>
          <span>{{this.form.cargoShipmentRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>订单备注：</span>
          <span>{{this.form.orderRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>交单备注：</span>
          <span>{{this.form.deliveryRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>核算备注：</span>
          <span>{{this.form.checkRemark||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">图片信息</div>
        <!--图片-->
        <div class="order-img" :style="{'margin-right': (idx+1)%4===0?'0px':''}"
             v-for="(item, idx) in bigImgList" :key="idx">
          <el-image
                  :preview-src-list="imgSrcList"
                  :src="bigImages[idx]"
                  fit="cover"
                  style="width: 100%;height: 100%;border-radius: 4px"
                  @click="onPreview(idx)"
                  lazy
                ></el-image>
          <div class="img-text">
            <div>
              <span>{{item.userName||'- -'}}</span>
              &nbsp;
              <span>{{item.uploaderPhone||'- -'}}</span>
            </div>
            <span>{{item.gmtCreate|str2ymdhm}}</span>
          </div>
          <div class="img-icon">
            {{item.imageType}}
          </div>
          <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i>
        </div>
      </div>
      <div class="btn-main">
        <!--<el-button type="primary" @click="clickSubmit('通过')" :loading="btnLoading" v-if="!$route.query.page">通-->
          <!--过-->
        <!--</el-button>-->
        <!--<el-button type="danger" class="btn-danger" @click="dialogFormVisible = true" :loading="btnLoading"-->
                   <!--v-if="!$route.query.page">不通过-->
        <!--</el-button>-->
        <el-button @click="$router.go(-1)">返 回</el-button>
      </div>
    </div>
    <el-dialog title="核算不通过原因" :visible.sync="dialogFormVisible" width="540px" center>
      <div>
        <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="checkRemark">
        </el-input>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickSubmit('不通过')" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        bigImgList:[],
        bigImages:[],
        imgSrcList:[],
        nav: [
          {name: '订单核算', path: '/order/order-accounting'},
          {name: '核算'}
        ],
        form: {},
        stepList: [],
        active: null,
        btnLoading: false,
        dialogFormVisible: false,
        checkRemark: null
      }
    },
    methods: {
      // 依次查看大图
      onPreview(index){
        let arr1 = this.bigImages.filter((arrItem, arrIndex) => index <= arrIndex);
        let arr2 = this.bigImages.filter((arrItem, arrIndex) => index > arrIndex);
        let arr3 = arr2.sort((a, b) => {
          b - a;
        });
        this.imgSrcList = [...arr1, ...arr3];
      },


      async clickSubmit (e) {
        if (e === '不通过' && !this.checkRemark) {
          this.$message.warning('请输入内容')
          return
        }
        this.btnLoading = true
        try {
          const _steelOrderInfoVO = {
            orderId: this.form.orderId,
            orderType: this.form.orderType,
            orderStatus: e === '通过' ? '已核算' : '已交单',
          }
          if (e==='不通过') {
            _steelOrderInfoVO.checkRemark = this.checkRemark
          }
          await this.$api.OrderUpdate({
            type: 1,
            steelOrderInfoVO:_steelOrderInfoVO
            //   {
            //   orderId: this.form.orderId,
            //   orderType: this.form.orderType,
            //   orderStatus: e === '通过' ? '已核算' : '已交单',
            //   // imageInfoVOS: this.form.imageInfoVOS.map(e => {
            //   //   return {
            //   //     imageUrl: e.imageKey,
            //   //     imageType: e.imageType,
            //   //     imageRemark: e.imageRemark,
            //   //   }
            //   // })
            // }
          })
          this.$notify({
            title: '成功',
            message: `${e === '通过' ? '操作成功' : '修改成功'}`,
            type: 'success'
          })
          this.$router.go(-1)
        } catch (e) {
          console.log(e)
        }
        this.btnLoading = false
      }
    },
    // beforeRouteLeave (to, from, next) {
    //   to.meta.keepAlive = true
    //   next()
    // },
    async created () {
      if (this.$route.query.page === '对账') {
        this.nav = [
          {name: '会计对账'},
          {name: '客户对账', path: '/accounting/customer-reconciliation'},
          {name: '订单明细', path: 'go(-1)'},
          {name: '订单详情'}
        ]
      }
      if (this.$route.query.page === '内部驾驶员') {
        this.nav = [
          {name: '会计对账'},
          {name: '内部驾驶员统计', path: '/accounting/inside-driver'},
          {name: '承运明细', path: 'go(-1)'},
          {name: '订单详情'}
        ]
      }
      if (this.$route.query.page === '外部驾驶员') {
        this.nav = [
          {name: '会计对账'},
          {name: '外请车主统计', path: '/accounting/external-driver'},
          {name: '承运明细', path: 'go(-1)'},
          {name: '订单详情'}
        ]
      }
      // console.log(this.$route.query.activePath)
      this.form = await this.$api.OrderGet({
        orderId: this.$route.query.orderId
      })
      this.stepList = this.form.orderType === '外发' ? this.$enums.OrderStatus_w : this.$enums.OrderStatus_n
      this.stepList = this.form.operationLogInfoVOList[0].driverOrOperator ? this.stepList : this.$_.drop(this.stepList, 1)
      this.active = this.form.operationLogInfoVOList.length
      this.form.imageInfoVOS.forEach(item=>{
        if(item.imageUrl.length>=2){
          for (let i of item.imageUrl) {
            this.bigImages.push(i)
              this.bigImgList.push({
                gmtCreate: item.gmtCreate,
                gmtModified: item.gmtModified,
                imageRemark: item.imageRemark,
                imageType: item.imageType,
                imageUrl: i,
                orderId: item.orderId,
                tenantId: item.tenantId,
                uploaderPhone: item.uploaderPhone,
                userIdentity: item.userIdentity,
                userName: item.userName,
              })
          }
        }else{
          let img = item.imageUrl[0]
          this.bigImages.push(img)
          this.bigImgList.push(item)
        }
      })

    },
    // async activated () {
    //   if (this.$route.query.page === '对账') {
    //     this.nav = [
    //       {name: '会计对账'},
    //       {name: '结算客户对账', path: '/accounting/customer-reconciliation'},
    //       {name: '订单明细', path: 'go(-1)'},
    //       {name: '订单详情'}
    //     ]
    //   }
    //   if (this.$route.query.page === '内部驾驶员') {
    //     this.nav = [
    //       {name: '会计对账'},
    //       {name: '内部驾驶员统计', path: '/accounting/inside-driver'},
    //       {name: '承运明细', path: 'go(-1)'},
    //       {name: '订单详情'}
    //     ]
    //   }
    //   if (this.$route.query.page === '外部驾驶员') {
    //     this.nav = [
    //       {name: '会计对账'},
    //       {name: '外请车主统计', path: '/accounting/external-driver'},
    //       {name: '承运明细', path: 'go(-1)'},
    //       {name: '订单详情'}
    //     ]
    //   }
    //   // console.log(this.$route.query.activePath)
    //   this.form = await this.$api.OrderGet({
    //     orderId: this.$route.query.orderId
    //   })
    //   this.stepList = this.form.orderType === '外发' ? this.$enums.OrderStatus_w : this.$enums.OrderStatus_n
    //   this.stepList = this.form.operationLogInfoVOList[0].driverOrOperator ? this.stepList : this.$_.drop(this.stepList, 1)
    //   this.active = this.form.operationLogInfoVOList.length
    //   this.form.imageInfoVOS.forEach(item=>{
    //     if(item.imageUrl.length>=2){
    //       for (let i of item.imageUrl) {
    //         this.bigImages.push(i)
    //           this.bigImgList.push({
    //             gmtCreate: item.gmtCreate,
    //             gmtModified: item.gmtModified,
    //             imageRemark: item.imageRemark,
    //             imageType: item.imageType,
    //             imageUrl: i,
    //             orderId: item.orderId,
    //             tenantId: item.tenantId,
    //             uploaderPhone: item.uploaderPhone,
    //             userIdentity: item.userIdentity,
    //             userName: item.userName,
    //           })
    //       }
    //     }else{
    //       let img = item.imageUrl[0]
    //       this.bigImages.push(img)
    //       this.bigImgList.push(item)
    //     }
    //   })

    // },
  }
</script>

<style lang='sass' scoped>
/deep/ .el-image-viewer__close
   color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
   border: 3px solid #fff
</style>
